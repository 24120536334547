<template>
  <ag-card-container fluid :title="$t('title.home')" />
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'

export default {
  name: 'HomeContainer',
  components: {
    AgCardContainer,
  },
}
</script>
